/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"volná"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--style2 --full --parallax" anim={null} name={"tcjhsd8px4n"} animS={null} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} border={null} parallax={true} fullscreen={true}>
          
          <ColumnWrap className="column__flex --center el--2 --full flex--center" style={{"maxWidth":1125,"marginTop":0,"paddingTop":0,"backgroundColor":"rgba(255,255,255,1)"}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--shape3" border={null}>
              
              <Title className="title-box title-box--left ff--3 fs--48 title-box--invert" content={"<font color=\"rgba(0,0,0,1)\">Prodejna</font>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--20 text-box--invert" style={{"maxWidth":650}} content={"<font color=\"#c81313\">Naše kamenná prodejna se nachází na adrese<br>Ledařská 443/9, Praha 4.</font>"}>
              </Text>

              <Text className="text-box text-box--left w--500" content={"<font color=\"#ca1313\">Nabízíme zboží značek, se kterými se sami potápíme.</font>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18 w--600" style={{"paddingTop":12}} content={"<b><font color=\"rgba(0,0,0,1)\">Jsme schopni pro Vás zajistit vybavení od výrobců:</font></b>"}>
              </Text>

              <Text className="text-box text-box--left fs--16 w--500" style={{"marginTop":5.875,"paddingTop":0}} content={"<b><i>Apeks, Aqualung, Bare, Beauchat, Cressi, Divesoft, Dux, Finnsub, Hollis, Jet Stream, Lola, Mares, Oceanic, Scubapro, Suunto, Technisub, Tecline, Ursuit a další.\n\n<br><br>Stavte se u nás na prodejně, rádi Vám s výběrem poradíme.</i></b>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3" border={null}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/fc04f86c62884321bbcc09c792a8a9ee_s=860x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, 50vw" style={{"maxWidth":600,"paddingTop":1.04998779296875}} srcSet={"https://cdn.swbpg.com/t/13078/fc04f86c62884321bbcc09c792a8a9ee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/fc04f86c62884321bbcc09c792a8a9ee_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/fc04f86c62884321bbcc09c792a8a9ee_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/fc04f86c62884321bbcc09c792a8a9ee_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"lb1059hefql"} style={{"marginTop":0,"paddingTop":10,"paddingBottom":6.958343505859375,"backgroundColor":"rgba(162, 187, 192, 1)"}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"marginTop":0,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/4edf3c0a76c542fba384f48027a09a0f_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-46.35193133047207%","y":"-9.012875536480687%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7af94b90fb69441383ec3897c60f82c0_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-25.751072961373396%","y":"-3.0042918454935617%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/ccc41ad2ef984718b33d14fd64fa7037_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/9ca16892de6549c285050e97251bffba_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/10ab314cfa55430182d4a092c85845ea_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-53.14592274678112%","y":"-0.4291845493562232%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}